<template>
  <q-page class="cms-page ql-editor">
    <InnerLoadingSpinner :showing="loading" />
    <div class="page-title" v-if="!loading && pageData">
      <div class="page-title--left">
        <h1 v-html="pageData.pageDetailsTitle" />
        <q-breadcrumbs>
          <q-breadcrumbs-el label="Home" :to="{ name: 'Home' }" />
          <q-breadcrumbs-el :label="pageData.pageDetailsTitle" />
        </q-breadcrumbs>
      </div>
    </div>
    <div v-if="!loading && pageData" class="container">
      <div class="card-content">
        <div v-html="pageData.content" />
      </div>
    </div>
  </q-page>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CMSPages',
  props: ['slugId'],
  /* preFetch({ store, currentRoute, redirect }) {
    let { slugId } = currentRoute.params,
      promise

    promise = store
      .dispatch('cmsPages/getCMSPageDetails', slugId)
      .then((data) => {
        if (!data) {
          redirect({
            name: 'Home',
          })
        }
      })

    if (process.env.SERVER) return promise
  }, */
  meta() {
    return {
      title: this.pageData ? this.pageData.pageDetailsTitle : '',
      ...this.commonMeta,
    }
  },
  computed: {
    ...mapGetters('cmsPages', ['getCmsPageById', 'loading']),
    pageData() {
      return this.getCmsPageById(this.slugId)
    },
  },
  mounted() {
    // prefetch code
    let { slugId } = this.$route.params

    this.$store.dispatch('cmsPages/getCMSPageDetails', slugId).then((data) => {
      if (!data) {
        this.$router.push({
          name: 'Home',
        })
      }
    })
    // prefetch code
  },
}
</script>
<style lang="scss"></style>
